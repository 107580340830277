import CryptoJS from 'crypto-js'
import JSEncrypt from 'jsencrypt'
import { Base64 } from 'js-base64'

const publicKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDGWJVuZI40aM8/EjQuwWDbbubG
MeMRyZ29LmlHcVXM5g6qMom1Sgyhmwsie0+uISAd4pnbvHYSbXijLI58mvuh4P35
a01CmEjZ7fcVmQi//4Q5ZLiVW0dBeymzZ0LMjeT59juWnC+hayJuRyQtao4JfJ1P
xaldyufjXrSadtVBOQIDAQAB
-----END PUBLIC KEY-----`
const privateKey = '1234567890123456'

export function encryptData(data, key) {
  key = CryptoJS.enc.Utf8.parse(key || privateKey)
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    iv: CryptoJS.enc.Utf8.parse(''),
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
   }).toString()
  return Base64.encode(encryptedData)
}

export function decryptData(data, key) {
  data = Base64.decode(data)
  key = CryptoJS.enc.Utf8.parse(key || privateKey)
  const decryptedData = CryptoJS.AES.decrypt(data, key, {
    iv: CryptoJS.enc.Utf8.parse(''),
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }).toString(CryptoJS.enc.Utf8)
  return decryptedData
}

export function encryptRSA() {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return Base64.encode(encryptor.encrypt(privateKey)) // 对数据进行加密
}
