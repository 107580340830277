import request from '@/utils/request'

export function getPkey (parameter) {
  return request({
    url: '/api/getPkey',
    method: 'post',
    data: parameter
  })
}

export function cjQuestionList (parameter) {
  return request({
    url: '/api/cjQuestionList',
    method: 'get',
    params: parameter
  })
}

export function vipList (parameter) {
  return request({
    url: '/api/vipList',
    method: 'get',
    params: parameter
  })
}

export function zsList (parameter) {
  return request({
    url: '/api/zsList',
    method: 'get',
    params: parameter
  })
}

export function getSetting (parameter) {
  return request({
    url: '/api/getSetting',
    method: 'get',
    params: parameter
  })
}

export function sendsms (parameter) {
  return request({
    url: '/api/sendsms',
    method: 'post',
    data: parameter
  })
}

export function login (parameter) {
  return request({
    url: '/api/login',
    method: 'post',
    data: parameter
  })
}

export function userInfo (parameter) {
  return request({
    url: '/api/userInfo',
    method: 'post',
    data: parameter
  })
}

export function createOrder (parameter) {
  return request({
    url: '/api/createOrder',
    method: 'post',
    data: parameter
  })
}

export function pay (parameter) {
  return request({
    url: '/api/pay',
    method: 'post',
    data: parameter
  })
}

export function protocoDetail (parameter) {
  return request({
    url: '/api/protocoDetail',
    method: 'get',
    params: parameter
  })
}

export function gongCode (parameter) {
  return request({
    url: '/api/gongCode',
    method: 'get',
    params: parameter
  })
}

export function wxConfig (parameter) {
  return request({
    url: '/api/wxConfig',
    method: 'get',
    params: parameter
  })
}

export function dtDetail (parameter) {
  return request({
    url: '/api/dtDetail',
    method: 'get',
    params: parameter
  })
}

export function zpqDetail (parameter) {
  return request({
    url: '/api/zpqDetail',
    method: 'get',
    params: parameter
  })
}

export function userDetail (parameter) {
  return request({
    url: '/api/shareUserDetail',
    method: 'get',
    params: parameter
  })
}


