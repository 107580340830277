import axios from 'axios'
import storage from 'store'
import { encryptRSA, encryptData, decryptData } from './encrypt'
// import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'

// 不需要加密的接口
const pathArr = ['/api/gongCode']

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

// 异常拦截处理器
const errorHandler = (error) => {
  // if (error.response) {
  // }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = storage.get('USER_TOKEN')
  const rant = storage.get('USER_RANT')
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  let params = {}
  if (pathArr.includes(config.url)) {
    if (token) {
      params.token = token
    }
    if (config.method === 'post') {
      config.data = {
        ...config.data,
        token: token,
        rant: rant
      }
    }
    if (config.method === 'get') {
      config.params = {
        ...config.params,
        token: token,
        rant: rant
      }
    }
    return config
  }

  if (token) {
    params.token = token
  }
  if (config.method === 'post') {
    params = {
      ...params,
      ...config.data
    }
    config.data = {
      key: encryptRSA(),
      data: encryptData(params),
      rant: rant
    }
  }
  if (config.method === 'get') {
    params = {
      ...params,
      ...config.params
    }
    config.params = {
      key: encryptRSA(),
      data: encryptData(params),
      rant: rant
    }
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  // if (response.data && response.data.error === 401) {
  // }
  if (pathArr.includes(response.config.url)) {
    return response.data
  }
  return JSON.parse(decryptData(response.data))
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
