import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const pcRoutes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/pc/Home.vue'),
    meta: {
      title: '偶遇圈'
    }
  }, {
    path: '/about',
    name: 'About',
    component: () => import('../views/pc/About.vue'),
    meta: {
      title: '关于我们'
    }
  }, {
    path: '/question',
    name: 'Question',
    component: () => import('../views/pc/Question.vue'),
    meta: {
      title: '常见问题'
    }
  }, {
    path: '/recharge',
    name: 'Recharge',
    component: () => import('../views/pc/Recharge.vue'),
    meta: {
      title: '充值'
    }
  }, {
    path: '/login',
    name: 'Login',
    component: () => import('../views/pc/Login.vue'),
    meta: {
      title: '登录'
    }
  }, {
    path: '*',
    redirect: '/',
  }
]

const mobileRoutes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/mobile/Home.vue'),
    meta: {
      title: '偶遇圈'
    }
  }, {
    path: '/login',
    name: 'Login',
    component: () => import('../views/mobile/Login.vue'),
    meta: {
      title: '登录'
    }
  }, {
    path: '/recharge',
    name: 'Recharge',
    component: () => import('../views/mobile/Recharge.vue'),
    meta: {
      title: '充值'
    }
  }, {
    path: '/agreement',
    name: 'Agreement',
    component: () => import('../views/mobile/Agreement.vue'),
    meta: {
      title: '用户协议'
    }
  }, {
    path: '*',
    redirect: '/',
  }
]

const baseRoutes = [
  {
    path: '/zpq',
    name: 'Zpq',
    component: () => import('../views/Zpq.vue'),
    meta: {
      title: '偶遇圈'
    }
  }, 
  {
    path: '/circle',
    name: 'Circle',
    component: () => import('../views/Circle.vue'),
    meta: {
      title: '偶遇圈'
    }
  }, 
  {
    path: '/invite',
    name: 'Invite',
    component: () => import('../views/Invite.vue'),
    meta: {
      title: '偶遇圈'
    }
  }, 
  {
    path: '/friend',
    name: 'Friend',
    component: () => import('../views/Friend.vue'),
    meta: {
      title: '偶遇圈'
    }
  }
 ]

const isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)

const  routes = [
  ...isMobile ? mobileRoutes : pcRoutes,
  ...baseRoutes
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // if (isMobile && !isWeChat && to.path !== '/') {
  //   next('/')
  //   return
  // }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
