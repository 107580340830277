import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueAxios } from './utils/request'

import AOS from "aos";
import "../node_modules/aos/dist/aos.css";
Vue.use(AOS);

//import "animate.css"

import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)

import './assets/css/reset.css'

Vue.config.productionTip = false

Vue.use(VueAxios)


import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)


// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)

Vue.prototype.loginVis = false

new Vue({
  created() {
    AOS.init({
      disable: false,
      startEvent: 'DOMContentLoaded',
    });
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')

//App.provide('AOS', AOS)