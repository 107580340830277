import Vue from 'vue'
import Vuex from 'vuex'
import storage from 'store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    rant: '',
    userInfo: {}
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      storage.set('USER_TOKEN', token)
    },
    SET_RANT: (state, rant) => {
      state.rant = rant
      storage.set('USER_RANT', rant)
    },
    SET_USERINFO: (state, data) => {
      state.userInfo = data
    }
  },
  actions: {
  },
  modules: {
  }
})
