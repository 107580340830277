<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { userInfo } from '@/api/index'
import storage from 'store'
export default {
  mounted() {
    const token = storage.get('USER_TOKEN')
    if (token) {
      this.SET_TOKEN(token)
      this.handleGetUserInfo()
    }
  },
  methods: {
    ...mapMutations(['SET_TOKEN', 'SET_USERINFO']),
    handleGetUserInfo () {
      userInfo().then(res => {
        if (res.error === 0) {
          this.SET_USERINFO(res.info)
        } else {
          this.SET_TOKEN('')
        }
      })
    }
  }
}
</script>

<style lang="less">
input {
  background: none;
  border: none;
  outline: none;
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // width: 100%;
  // height: 100%;
  object-fit: cover;
}
</style>
